// @flow
import React from 'react';
import { graphql } from 'gatsby';

import type { WordPressPage } from '../types';
import { paths } from '../../config';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import PostListItem from '../components/PostListItem';
import type { PostListItemType } from '../components/PostListItem';
import { IntroText, PageHeader } from '../styles';
import Pagination from '../components/Pagination';
import { normalizeTags } from '../utils';

type Props = {
  pageContext: {
    numPages: number,
    currentPage: number,
    canonical: string,
  },
  data: {
    page: WordPressPage,
    allWordpressWpPostsAndGuides: {
      edges: Array<{
        node: PostListItemType & {
          id: string,
          alternativeTags?: Array<{
            path: string,
            name: string,
          }>
        },
      }>,
    },
  },
};

const BlogTemplate = (props: Props) => {
  const {
    pageContext: {
      numPages,
      currentPage,
      canonical,
    },
    data: {
      page,
      allWordpressWpPostsAndGuides,
    },
  } = props;

  const postsMarkup = (
    <div className="row">
      {allWordpressWpPostsAndGuides.edges.map((edge) => (
        <div key={edge.node.id} className="col-sm-6 col-md-4 col-lg-3">
          <PostListItem item={normalizeTags(edge.node)} />
        </div>
      ))}
    </div>
  );

  const paginationMarkup = numPages > 1 ? (
    <Pagination
      basePath={paths.blog}
      numPages={numPages}
      currentPage={currentPage}
    />
  ) : null;

  return (
    <Layout>
      <SEO
        title={page.customMeta.title}
        canonical={canonical}
        meta={page.yoastMeta}
      />
      <PageHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
        {page.acf && page.acf.introText && (<IntroText>{page.acf.introText}</IntroText>)}
      </PageHeader>
      {postsMarkup}
      {paginationMarkup}
    </Layout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query blogPageQuery($id: String!, $skip: Int!, $limit: Int!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      content
      yoastMeta: yoast_meta {
        name
        property
        content
      }
      customMeta: et_custom_meta {
        title
        canonical
      }
      acf {
        introText: intro_text
      }
    }
    allWordpressWpPostsAndGuides(
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          path
          excerpt
          type
          featuredImage: featured_media {
            alt: alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 308) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          tags {
            path
            name
          }
          alternativeTags: alternative_tags {
            path
            name
          }
          customMeta: et_custom_meta {
            commentCount: comment_count
          }
        }
      }
    }
  }
`;
